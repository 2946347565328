<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-btn text icon v-bind="attrs" v-on="on" @click="crop">
                <v-icon small color="white">crop</v-icon>
            </v-btn>
        </template>
        <span>Crop</span>
    </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ACropMediaActivator extends Vue {
    crop() {
        this.$emit('crop');
    }
}
</script>
