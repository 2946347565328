<template>
    <span>
        <a-replace-media-activator @replace="replace" />
        <a-replace-media-handler ref="handler" :box="box" v-on="$listeners" />
    </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import AReplaceMediaActivator from './AReplaceMediaActivator.vue';
import AReplaceMediaHandler from './AReplaceMediaHandler.vue';

import type { VideoBox } from '@/types/Video';

const AReplaceMediaProps = Vue.extend({
    name: 'AReplaceMedia',
    props: {
        box: {
            type: Object as PropType<VideoBox>,
            required: true
        }
    }
});

@Component({
    components: {
        AReplaceMediaActivator,
        AReplaceMediaHandler
    }
})
export default class AReplaceMedia extends AReplaceMediaProps {
    $refs!: {
        handler: InstanceType<typeof AReplaceMediaHandler>;
    };

    replace() {
        this.$refs.handler.replace();
    }
}
</script>
