<template>
    <a-image-cropper
        v-if="isCroppable"
        :id="cropperId"
        :options="cropperOptions"
        :can-slide-zoom="false"
        @data="onCrop"
    />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import { ResourceFile, MediaModuleMedia } from '@/mixins';

import { AImageCropper } from '@/components/AForm/Inputs/AMediaUploader';

import type { VideoBox } from '@/types/Video';
import type Cropper from 'cropperjs';

const ACropMediaHandlerProps = Vue.extend({
    name: 'ACropMediaHandler',
    props: {
        box: {
            type: Object as PropType<VideoBox>,
            required: true
        }
    }
});

@Component({
    components: {
        AImageCropper
    }
})
export default class ACropMediaHandler extends mixins(
    ACropMediaHandlerProps,
    MediaModuleMedia,
    ResourceFile
) {
    _uid!: number;

    cropperOptions = {
        id: '',
        file: '',
        instructions: {
            data: {},
            cropbox: {},
            canvas: {},
            zoom: {
                factor: 0,
                value: 0
            }
        },
        options: {
            aspectRatio: this.aspectRatio,
            zoomOnWheel: true,
            viewMode: 1 // we don't want cropbox to be bigger than the image
        }
    };

    get resource() {
        return this.box?.video_media_box?.media_resource;
    }

    get isCroppable() {
        return this.resource?.media_file?.type === 0 && this.fileSrc;
    }

    get isExternalStock() {
        return this.resource?.media_file.is_external_stock;
    }

    get isFullResStock() {
        return Boolean(
            this.isExternalStock && this.resource?.media_file.filename
        );
    }

    get fileSrc() {
        return this.resource?.media_file?.relative_filename || '';
    }

    get aspectRatio() {
        if (
            this.box?.video_media_box &&
            this.box.video_media_box.preserve_aspect_ratio
        ) {
            return this.box.width / this.box.height;
        }

        return NaN;
    }

    get index() {
        return this.box?.id ? this.box?.id + this._uid : this._uid;
    }

    get cropperId() {
        return `media-cropper-dialog-${this.index}`;
    }

    crop() {
        this.cropperOptions = {
            id: String(this.index),
            file: this.fileSrc,
            instructions: {
                data: this.getBoxCropData(),
                cropbox: {},
                canvas: {},
                zoom: {
                    factor: 0,
                    value: 0
                }
            },
            options: {
                aspectRatio: this.aspectRatio,
                zoomOnWheel: true,
                viewMode: 1
            }
        };

        this.openCropper();
    }

    openCropper() {
        this.$store.dispatch('modal/open', this.cropperId);
    }

    closeCropper() {
        this.$store.dispatch('modal/close', this.cropperId);
    }

    onCrop({ data }: { data: { data: Cropper.Data } }) {
        this.setBoxCropData(data.data);

        this.closeCropper();
    }

    getBoxCropData() {
        if (this.resource) {
            return {
                height: this.resource.crop_height,
                width: this.resource.crop_width,
                x: this.resource.crop_x,
                y: this.resource.crop_y
            };
        }

        return {};
    }

    setBoxCropData(data: Cropper.Data) {
        if (this.resource) {
            const { width, height, x, y } = data;
            // store as thumbnail_crop so we can re-scale in @collectMediaResources
            if (this.isExternalStock && !this.isFullResStock) {
                this.resource.thumbnail_crop = {
                    height,
                    width,
                    x,
                    y
                };
            }

            this.resource.crop_height = height;
            this.resource.crop_width = width;
            this.resource.crop_x = x;
            this.resource.crop_y = y;

            this.$emit('change');
        }
    }
}
</script>
