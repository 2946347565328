<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-btn text icon v-bind="attrs" v-on="on" @click="replace">
                <v-icon small color="white">repeat</v-icon>
            </v-btn>
        </template>
        <span>Replace</span>
    </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AReplaceMediaActivator extends Vue {
    replace() {
        this.$emit('replace');
    }
}
</script>
