<template>
    <div
        class="video-box"
        :data-id="box.id"
        :class="getClassesForBox()"
        :style="getStyleForBox(box, index)"
    >
        <div
            :class="calculateCssClasses()"
            :style="getInnerStylesFromVideoBox()"
        >
            <a-video-media-box
                v-if="box.video_media_box"
                :box="box.video_media_box"
                @click.native="onBoxAction"
                @contextmenu.native="onBoxAction"
            />
            <template v-if="box.video_text_box">
                <a-rich-content-editable
                    v-if="emphasis"
                    :content="text"
                    :emphasis="emphasis"
                    :with-backdrop="hasBackdrop"
                    :placeholder="placeholder"
                    :readonly="readonly"
                    :max-length="box.video_text_box.max_length"
                    @change="onContentUpdate"
                    @validate="onContentValidate"
                    @focus="activate"
                    @blur="deactivate"
                    @mouseenter.native="over"
                    @mouseleave.native="out"
                />
                <a-content-editable
                    v-else
                    :content="text"
                    :with-backdrop="hasBackdrop"
                    :placeholder="placeholder"
                    :readonly="readonly"
                    :max-length="box.video_text_box.max_length"
                    @change="onContentUpdate"
                    @validate="onContentValidate"
                    @focus="activate"
                    @blur="deactivate"
                    @mouseenter.native="over"
                    @mouseleave.native="out"
                />
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import {
    CroppedResource,
    MediaBox,
    MediaModuleMedia,
    ResourceFile
} from '@/mixins';

import {
    AContentEditable,
    ARichContentEditable
} from '@/components/AContentEditable';
import { AVideoMediaBox } from '@/components/AVideoMediaBox';

import {
    VideoTextBoxAlignX,
    type MediaAspectRatio,
    type MediaStyle,
    type TextEmphasis,
    type VideoBox,
    type VideoFont,
    VideoTextBox
} from '@/types/Video';
import type { CSSProperties } from 'vue/types/jsx';
import { calculateSize } from '@/utils/helpers';

const MAX_FONT_SIZE_FACTOR = 1.7; // don't upscale to more than this
const TEXT_SHADOW_FACTOR = 3; // multiple template value to match BE TextRenderer

const AVideoBoxProps = Vue.extend({
    name: 'AVideoBox',
    props: {
        box: {
            type: Object as PropType<VideoBox>,
            required: true
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            }
        },
        index: {
            type: Number,
            default() {
                return 1;
            }
        }
    }
});

@Component({
    components: {
        AContentEditable,
        ARichContentEditable,
        AVideoMediaBox
    }
})
export default class AVideoBox extends mixins(
    AVideoBoxProps,
    MediaModuleMedia,
    ResourceFile,
    CroppedResource,
    MediaBox
) {
    flags = {
        over: false,
        active: false,
        valid: true
    };

    fontFamily = 'inherit';
    fontsLoading: Promise<void> | null = null;

    get placeholder() {
        return `Click to add ${(
            this.fieldLabel || 'text'
        ).toLocaleLowerCase()}`;
    }

    get isCropped() {
        return (
            this.box.video_media_box?.media_resource.crop_x !== null &&
            this.box.video_media_box?.media_resource.crop_y !== null &&
            this.box.video_media_box?.media_resource.crop_width !== null &&
            this.box.video_media_box?.media_resource.crop_height !== null
        );
    }

    get fieldLabel() {
        if (!this.box.video_text_box) {
            return '';
        }

        if (this.box.video_text_box.video_caption.text) {
            return this.box.video_text_box.video_caption.text;
        }

        if (this.box.video_text_box.example_text) {
            return this.box.video_text_box.example_text;
        }

        return this.box.description;
    }

    get text() {
        if (!this.box.video_text_box) {
            return '';
        }

        return this.box.video_text_box.video_caption.text || '';
    }

    get emphasis(): TextEmphasis | null {
        return this.box.video_text_box?.emphasis_font
            ? {
                  font: this.box.video_text_box?.emphasis_font,
                  color: this.box.video_text_box?.emphasis_color,
                  offset: this.box.video_text_box.outline_size
              }
            : null;
    }

    get hasBackdrop() {
        return Boolean(this.box?.video_text_box?.backdrop_color);
    }

    onContentUpdate(value: string) {
        if (this.box.video_text_box) {
            this.box.video_text_box.video_caption.text = value || '';

            this.$emit('change');
        }
    }

    onContentValidate(isValid: boolean) {
        this.flags.valid = isValid;
    }

    calculateCssClasses() {
        const classes = [`amp-${this.box.type}`];

        if (this.box.video_media_box) {
            classes.push(...this.calculateResourceCssClasses());
        }

        if (this.box.video_text_box) {
            classes.push(...this.calculateCaptionCssClasses());
        }

        return [...new Set(classes)];
    }

    calculateCaptionCssClasses() {
        const classes = [];
        const box = this.box.video_text_box;

        if (box) {
            if (box.align_x === VideoTextBoxAlignX.Center) {
                classes.push('amp-video_text_box-align-center');
            }

            if (box.align_x === VideoTextBoxAlignX.Right) {
                classes.push('amp-video_text_box-align-right');
            }

            if (box.center_y) {
                classes.push('amp-video_text_box-valign-center');
            }
        }

        return classes;
    }

    calculateResourceCssClasses() {
        const classes = [];
        if (
            !this.box.video_media_box?.zoompan &&
            !this.isCropped &&
            this.box.video_media_box?.preserve_aspect_ratio
        ) {
            classes.push('amp-video_media_box-center');
        }

        if (!this.readonly && this.mediaIsChangeable(this.box)) {
            classes.push('amp-video_media_box-clickable');
        }

        return classes;
    }

    getInnerStylesFromVideoBox(): CSSProperties {
        return {
            ...this.calculateMediaStyle(),
            ...this.calculateCaptionStyle()
        };
    }

    calculateMediaStyle(): CSSProperties {
        if (this.box.video_media_box) {
            const file = this.getResourceFile(this.box.video_media_box);

            if (!file || !this.box.video_media_box.preserve_aspect_ratio) {
                return {};
            }

            if (this.isCropped) {
                return this.calculateCroppedStyle(this.box, file);
            }

            const result: MediaStyle = {
                left: 0,
                top: 0,
                width: 0,
                height: 0
            };

            const aspectRatio = this.calculateAspectRatioFit(
                file.width,
                file.height,
                this.box.width,
                this.box.height
            );

            this.scaleToFit(result, aspectRatio);

            this.centerMedia(result);

            return {
                top: `${result.top}px`,
                left: `${result.left}px`,
                width: `${result.width}px`,
                height: `${result.height}px`,
                transform: 'translate(0)'
            };
        }

        return {};
    }

    calculateAspectRatioFit(
        srcWidth: number,
        srcHeight: number,
        maxWidth: number,
        maxHeight: number
    ): MediaAspectRatio {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    scaleToFit(media: MediaStyle, scale: MediaAspectRatio) {
        if (scale.width && scale.height) {
            media.width = scale.width;
            media.height = scale.height;
        }

        if (media.width < this.box.width) {
            const scaleRatio = this.box.width / media.width;
            media.width = this.box.width;
            media.height = media.height * scaleRatio;
        } else if (media.height < this.box.height) {
            const scaleRatio = this.box.height / media.height;
            media.height = this.box.height;
            media.width = media.width * scaleRatio;
        }
    }

    centerMedia(media: MediaStyle) {
        // non-cropped media should be centered
        if (media.width !== this.box.width) {
            // center horizontally
            if (media.width > this.box.width) {
                media.left = -1 * (media.width / 2 - this.box.width / 2);
            } else {
                media.left = this.box.width / 2 - media.width / 2;
            }
        }

        if (media.height !== this.box.height) {
            // center vertically
            if (media.height > this.box.height) {
                media.top = -1 * (media.height / 2 - this.box.height / 2);
            } else {
                media.top = this.box.height / 2 - media.height / 2;
            }
        }
    }

    calculateCaptionStyle(): CSSProperties {
        if (this.box.video_text_box) {
            this.calculateFontFamily();

            const styles: CSSProperties = {
                fontFamily: this.fontFamily,
                fontSize: this.calculateFontSize(),
                color: this.box.video_text_box.color,
                textAlign: this.getHorizontalAlign(this.box.video_text_box),
                textTransform: this.box.video_text_box.to_upper
                    ? 'uppercase'
                    : 'none',
                '--box-drop-color': '',
                '--box-drop-opacity': '',
                '--box-outline': '0px'
            };

            if (this.box.video_text_box?.backdrop_color) {
                const [color, opacity] =
                    this.box.video_text_box.backdrop_color.split('@');

                if (color) {
                    styles['--box-drop-color'] = color;
                }

                if (opacity) {
                    styles['--box-drop-opacity'] = `${opacity} !important`;
                }
            }

            if (this.box.video_text_box?.outline_size) {
                const offset =
                    this.box.video_text_box.outline_size * TEXT_SHADOW_FACTOR;

                styles['--box-outline'] = `${offset}px`;
            }

            if (this.box.video_text_box?.line_spacing) {
                const lineHeight = `${this.box.video_text_box?.line_spacing}em`;
                styles['line-height'] = lineHeight;
            }

            return styles;
        }

        return {};
    }

    getHorizontalAlign(box: VideoTextBox) {
        if (box.align_x === VideoTextBoxAlignX.Center) {
            return 'center';
        }

        if (box.align_x === VideoTextBoxAlignX.Right) {
            return 'right';
        }

        if (box.align_x === VideoTextBoxAlignX.Left) {
            return 'left';
        }

        return 'justify';
    }

    calculateFontFamily() {
        if (this.fontsLoading === null) {
            this.fontsLoading = Promise.all([
                this.box.video_text_box?.video_font
                    ? this.loadFontsForCaption(
                          this.box.video_text_box.video_font
                      )
                    : null,
                this.box.video_text_box?.emphasis_font
                    ? this.loadFontsForCaption(
                          this.box.video_text_box.emphasis_font
                      )
                    : null
            ]).then(([mainFont, emphasisFont]) => {
                const fontFamily = mainFont || emphasisFont;

                if (fontFamily) {
                    this.fontFamily = fontFamily;
                }
            });
        }

        return this.fontsLoading;
    }

    async loadFontsForCaption(font: VideoFont) {
        if (this.isFontPresent(font)) {
            return font.css_name;
        }

        const fontface = new FontFace(
            font.css_name,
            `url(${font.relative_route})`
        );

        return fontface
            .load()
            .then(this.onFontLoaded.bind(this))
            .catch(() => this.fallbackToGoogleFonts(font.css_name));
    }

    fallbackToGoogleFonts(name: string) {
        const fontface = new FontFace(
            name,
            `url('https://fonts.googleapis.com/css2?family=${name}&display=swap)`
        );

        return fontface
            .load()
            .then(this.onFontLoaded.bind(this))
            .catch(() => {
                //silently
                return null;
            });
    }

    onFontLoaded(loadedFontFace: FontFace) {
        document.fonts.add(loadedFontFace);

        return loadedFontFace.family;
    }

    isFontPresent(font: VideoFont) {
        return Array.from(document.fonts).find(f => f.family === font.css_name);
    }

    calculateFontSize() {
        if (this.box.video_text_box) {
            let fontSize = this.box.video_text_box.font_size;

            if (
                this.box.video_text_box.max_length > 40 &&
                this.text.length &&
                this.box.video_text_box.use_dynamic_font_size
            ) {
                fontSize = this.calculateScaledFontSize(this.text, fontSize);
            }

            return `${fontSize}px`;
        }

        return 'inherit';
    }

    calculateScaledFontSize(text: string, fontSize: number) {
        if (this.box.video_text_box) {
            const font = this.fontFamily;
            const width = `${this.box.width}px`;
            const maxFontSize = fontSize * MAX_FONT_SIZE_FACTOR;

            for (
                let proposedSize = fontSize;
                proposedSize <= maxFontSize;
                proposedSize += 1
            ) {
                const { height } = calculateSize(text, {
                    font,
                    width,
                    fontSize: `${proposedSize}px`,
                    lineHeight: '1em'
                });

                if (height > this.box.height) {
                    break;
                }

                fontSize = proposedSize;
            }
        }

        return fontSize;
    }

    onBoxAction(e: PointerEvent) {
        if (!this.readonly && this.mediaIsChangeable(this.box)) {
            e.preventDefault();

            this.$emit('action', e, this.box);
        }
    }

    getClassesForBox() {
        const classes: string[] = [];

        if (this.box.video_media_box && !this.mediaIsChangeable(this.box)) {
            classes.push('unchangeable');
        }

        if (this.flags.active || this.flags.over) {
            classes.push('box-active');
        }

        if (!this.flags.valid) {
            classes.push('box-invalid');
        }

        if (this.hasBackdrop) {
            classes.push('with-backdrop');
        }

        return classes.join(' ');
    }

    activate() {
        this.flags.active = true;
    }

    deactivate() {
        this.flags.active = false;
    }

    over() {
        if (!this.readonly) {
            this.flags.over = true;
        }
    }

    out() {
        if (!this.readonly) {
            this.flags.over = false;
        }
    }
}
</script>

<style lang="scss">
.video-box {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        transition: 0.2s ease;
    }

    &.box-active {
        &:before {
            border: 5px solid green;
            background-color: var(--box-text-color);
            opacity: 0.3;
        }

        &.box-invalid:before {
            background-color: $error;
        }
    }

    &.box-invalid {
        &:before {
            border: 5px solid $error;
            opacity: 0.8;
        }
    }

    &.unchangeable {
        pointer-events: none;
    }
}
/**
    Generic styles for different types of boxes
 */
.amp-video_color_box {
    position: absolute;

    div {
        position: absolute;
    }
}

.amp-video_text_box {
    position: absolute;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 1em;
    cursor: pointer;

    &-valign-center {
        top: 50%;
        transform: translateY(-50%);

        &.amp-video_text_box-align-center {
            transform: translate(-50%, -50%);
        }
    }

    &-align-center {
        left: 50%;
        transform: translateX(-50%);
    }

    &-align-right {
        left: 100%;
        transform: translateX(-100%);

        &.amp-video_text_box-valign-center {
            transform: translate(-100%, -50%);
        }
    }
}

.with-backdrop {
    &:hover,
    &.box-active {
        .content-editable.with-backdrop {
            display: inherit;
            line-height: normal;
            padding: unset;
            padding-top: 3px;
        }
    }
}

.amp-video_media_box-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
