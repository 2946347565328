<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-btn text icon v-bind="attrs" v-on="on" @click="toggle">
                <v-icon small color="white">{{ icon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ label }}</span>
    </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

@Component
export default class AThemeToggle extends Vue {
    get icon() {
        return this.useLightTheme ? 'moon' : 'sun';
    }

    get label() {
        return this.useLightTheme ? 'Dark mode' : 'Light mode';
    }

    @InjectReactive({
        from: 'useLightTheme',
        default() {
            return false;
        }
    })
    useLightTheme!: false;

    toggle() {
        this.$emit('toggle-theme');
    }
}
</script>
