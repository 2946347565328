<template>
    <span
        v-click-outside="deactivate"
        class="rich-content-editable-container"
        @click="activate"
    >
        <!-- eslint-disable vue/no-v-html -->
        <div
            v-show="!isActive"
            class="rich-layer"
            :style="richLayerStyle"
            v-html="richContent"
        ></div>
        <!-- eslint-enable -->
        <a-content-editable
            ref="editable"
            :class="{ invisible: !isActive }"
            :content="content"
            :readonly="readonly"
            v-bind="$attrs"
            v-on="$listeners"
        />
    </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { innerDimensions } from '@/utils/helpers';

import { AContentEditable } from '.';

import type { TextEmphasis } from '@/types/Video';

type RichPart = {
    text: string;
    bold?: boolean;
};

const ARichContentEditableProps = Vue.extend({
    name: 'ARichContentEditable',
    props: {
        content: {
            type: String,
            required: true,
            default() {
                return '';
            }
        },
        emphasis: {
            type: Object as PropType<TextEmphasis | null>,
            default(): TextEmphasis | null {
                return null;
            }
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AContentEditable
    }
})
export default class ARichContentEditable extends ARichContentEditableProps {
    $refs!: {
        editable: AContentEditable;
    };

    isActive = false;

    richLayerStyle = '';

    get richContent() {
        // replicate BE behavior
        const emphCount = this.content.split('*').length - 1;

        return emphCount % 2
            ? this.content
            : this.toHtml(this.toRich(this.content));
    }

    @Watch('content')
    onContentChange() {
        this.setRichStyle();
    }

    mounted() {
        this.setRichStyle();
    }

    setRichStyle() {
        const { width, height } = innerDimensions(
            this.$refs.editable.$refs.input
        );

        this.richLayerStyle = [
            'position:absolute',
            `width:${width}px`,
            `height:${height}px`
        ].join(';');

        this.setVars();
    }

    setVars() {
        const element = this.$el as HTMLDivElement;

        element.style.setProperty(
            '--emphasis-font',
            this.emphasis?.font.css_name || null
        );

        element.style.setProperty(
            '--emphasis-color',
            this.emphasis?.color || null
        );

        if (this.emphasis?.offset) {
            element.style.setProperty(
                '--emphasis-shadow',
                `${this.emphasis?.offset}px ${this.emphasis?.offset}px #666` // #666 is hardcoded in TextRenderer
            );
        }
    }

    activate() {
        if (!this.readonly) {
            this.isActive = true;
        }
    }

    deactivate() {
        this.isActive = false;
    }

    toRich(input: string) {
        let isBold = false;

        const output: RichPart[] = [];

        const text = input.split('').reduce((acc, char) => {
            if (char === '*') {
                output.push({
                    text: acc,
                    bold: isBold
                });

                isBold = !isBold;

                return '';
            } else {
                return acc + char;
            }
        }, '');

        if (text !== '') {
            output.push({ text });
        }

        return output;
    }

    toHtml(input: RichPart[]) {
        return input
            .reduce((acc: string[], part) => {
                let text = part.text;

                if (part.bold) {
                    text = `<b>${text}</b>`;
                }

                acc.push(text);

                return acc;
            }, [])
            .join('');
    }
}
</script>

<style lang="scss" scoped>
.rich-content-editable-container::v-deep {
    .rich-layer {
        pointer-events: none;
        cursor: text;
        outline: none;
        // fit entire line-height
        font-size: 85%;
        // preserve whitespace
        white-space: pre-line;

        b {
            font-family: var(--emphasis-font);
            color: var(--emphasis-color);
            text-shadow: var(--emphasis-shadow);
        }
    }

    .invisible {
        opacity: 0;
    }
}
</style>
