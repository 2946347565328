<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-btn text icon v-bind="attrs" v-on="on" @click="toggle">
                <v-icon small color="white">{{ icon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ label }}</span>
    </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

@Component
export default class AFullScreen extends Vue {
    get icon() {
        return this.isFullScreen ? 'compress' : 'expand';
    }

    get label() {
        return this.isFullScreen ? 'Exit full screen' : 'Full screen';
    }

    @InjectReactive({
        from: 'isFullScreen',
        default() {
            return false;
        }
    })
    isFullScreen!: false;

    toggle() {
        this.$emit('toggle-zoom');

        if (this.isFullScreen) {
            this.bind();
        } else {
            this.unbind();
        }
    }

    bind() {
        window.addEventListener('keyup', this.handleKeyUp);
    }

    unbind() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyUp(e: KeyboardEvent) {
        if (this.isFullScreen && e.code === 'Escape') {
            this.toggle();
        }
    }
}
</script>
