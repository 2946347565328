<template>
    <div
        ref="input"
        :placeholder="placeholder"
        :contenteditable="!readonly"
        :spellcheck="false"
        class="content-editable"
        :class="{
            'with-backdrop': withBackdrop,
            'is-empty': isEmpty
        }"
        @input="change"
        @focus="focus"
        @blur="blur"
        @paste="paste"
    ></div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { contentToText } from '@/utils/helpers';

const AContentEditableProps = Vue.extend({
    name: 'AContentEditable',
    props: {
        content: {
            type: String,
            required: true,
            default() {
                return '';
            }
        },
        placeholder: {
            type: String,
            default() {
                return 'Enter content here';
            }
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            }
        },
        withBackdrop: {
            type: Boolean,
            default() {
                return false;
            }
        },
        maxLength: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component
export default class AContentEditable extends AContentEditableProps {
    $refs!: {
        input: HTMLSpanElement;
    };

    get isEmpty() {
        return !this.content;
    }

    @Watch('content')
    onContentChange() {
        this.init(true);
    }

    getValue() {
        return contentToText(this.$refs.input) || '';
    }

    isValid(value: string) {
        return this.validatesRequired(value) && this.validatesMaxLength(value);
    }

    validatesRequired(value: string) {
        return Boolean(value.length);
    }

    validatesMaxLength(value: string) {
        return this.maxLength ? value.length <= this.maxLength : true;
    }

    mounted() {
        this.init();
    }

    init(emitChange = false) {
        if (this.getValue() !== this.content) {
            this.$refs.input.textContent = this.content;
        }

        if (!this.readonly) {
            this.$nextTick(() => {
                if (emitChange) {
                    this.$emit('change', this.content);
                }

                this.validate();
            });
        }
    }

    paste(e: ClipboardEvent) {
        e.preventDefault();

        // Get the copied text from the clipboard
        const text = (e.clipboardData?.getData('text/plain') || '').replace(
            /\r/gm,
            ''
        );

        if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text);
        } else {
            // Insert text at the current position of caret
            const range = document.getSelection()?.getRangeAt(0);

            if (range) {
                range.deleteContents();

                const textNode = document.createTextNode(text);
                range.insertNode(textNode);
                range.selectNodeContents(textNode);
                range.collapse(false);

                const selection = window.getSelection();
                selection?.removeAllRanges();
                selection?.addRange(range);
            }
        }
    }

    change() {
        if (!this.readonly) {
            this.$emit('change', this.getValue());

            this.validate();
        }
    }

    focus(event: FocusEvent) {
        if (!this.readonly) {
            this.$emit('focus', event);
        }
    }

    blur(event: FocusEvent) {
        if (!this.readonly) {
            this.trim();

            this.$emit('blur', event);
        }
    }

    trim() {
        const value = this.getValue();

        if (!value || this.$refs.input.textContent !== value) {
            // trim all of possible whitespace, avoiding empty ENTERs and SPACEs
            this.$refs.input.textContent = value;

            this.validate();
        }
    }

    validate() {
        this.$emit('validate', this.isValid(this.getValue()));
    }
}
</script>

<style lang="scss" scoped>
.content-editable::v-deep {
    cursor: text;
    outline: none;
    // preserve whitespace
    white-space: pre-line;

    &[contenteditable='true'] {
        &:empty:not(:focus):before {
            content: attr(placeholder);
            display: block;
            pointer-events: none;
        }
    }

    &.with-backdrop {
        &:not(.is-empty) {
            display: inline;
            line-height: 0;
            padding-bottom: 3px;
            padding-top: 3px;
        }
        background-color: var(--box-drop-color) !important;
    }

    text-shadow: var(--box-outline) var(--box-outline) #666; // #666 is hardcoded in TextRenderer
}
</style>
