<template>
    <media-dialog
        ref="MediaDialog"
        module-title="Amp"
        :index="index"
        :action="action"
        :allow-stock-video-search="videoStockSearch"
        :allow-user-images="allowUserImages"
        :allow-stock-media="allowStockMedia"
        :allow-multiple-selection="false"
        :options="dialogOptions"
        @confirm="onConfirm"
        @saved="onMediaSaved"
        @deleted="onMediaDeleted"
    />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import { MediaDialog } from '@/components/Media';

import { setMediaResources } from '@/utils/helpers';

import type { VideoBox } from '@/types/Video';

import type { FormMediaResource, MediaAction } from '@/types/Media';
import type { MediaFile } from '@/types/MediaFile';
import type { MediaResource } from '@/types/MediaResource';

const AReplaceMediaHandlerProps = Vue.extend({
    name: 'AReplaceMediaHandler',
    props: {
        box: {
            type: Object as PropType<VideoBox>,
            required: true
        }
    }
});

@Component({
    components: {
        MediaDialog
    }
})
export default class AReplaceMediaHandler extends AReplaceMediaHandlerProps {
    $refs!: {
        MediaDialog: InstanceType<typeof MediaDialog>;
    };

    @InjectReactive({
        from: 'videoStockSearch',
        default() {
            return false;
        }
    })
    videoStockSearch!: boolean;

    _uid!: number;

    action: MediaAction = 'Replace';

    mediaResources: FormMediaResource[] = [];

    get resource() {
        return this.box?.video_media_box?.media_resource;
    }

    get index() {
        return this.box?.id ? this.box?.id + this._uid : this._uid;
    }

    get dialogId() {
        return `media-dialog-${this.index}`;
    }

    get allowUserImages() {
        return this.box?.video_media_box?.allow_user_images === 1;
    }

    get allowStockMedia() {
        return this.box?.video_media_box?.allow_stock_media;
    }

    get mediaOptions() {
        return this.box.video_media_box
            ? {
                  video_media_box_id: this.box.video_media_box.id,
                  video_media_box_files: setMediaResources(
                      this.box.video_media_box.media_files
                  ),
                  // is internal stock
                  video_media_box_media_file_ids:
                      this.box.video_media_box.media_file_ids
              }
            : {};
    }

    get dialogOptions() {
        return {
            announcement_id: this.$route.params.announcementId,
            ...this.mediaOptions
        };
    }

    replace() {
        this.openMediaSelector();
    }

    openMediaSelector() {
        this.$store.dispatch('modal/open', this.dialogId);
    }

    closeMediaSelector() {
        this.$store.dispatch('modal/close', this.dialogId);
    }

    onMediaSaved(resources: FormMediaResource[]) {
        if (this.box?.video_media_box && resources.length) {
            this.replaceBoxMedia(resources[0].media_file);
        }

        this.closeMediaSelector();
    }

    replaceBoxMedia(file: MediaFile) {
        if (this.resource) {
            this.resource.media_file = file;
            // validation needs media_file_id
            this.resource.media_file_id = file.id;

            this.resetCrop(this.resource);

            this.$emit('change');
        }
    }

    onConfirm() {
        this.$refs.MediaDialog.save();
    }

    onMediaDeleted(ids: number[]) {
        this.$emit('media-deleted', ids);
    }

    resetCrop(resource: MediaResource) {
        resource.crop_height = null;
        resource.crop_width = null;
        resource.crop_x = null;
        resource.crop_y = null;
    }
}
</script>
